







































































import Vue from 'vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import CombinationTable from '../components/combinationTable.vue';
import HistoryList from './historyList.vue';
import EditQuota from './editQuota.vue';
import { deleteBillDe } from '@/api/costQuotaLibrary/detail';
import { checkUserIdentity } from '../index';
import QuotaDetail from './quotaDetail.vue';
const columns = [
  {
    title: '序号',
    key: 'serialNo',
  },
  {
    title: '项目特征',
    key: 'projectAttr',
    width: '40%',
  },
  {
    title: '综合单价(元)',
    key: 'price',
    dataIndex: 'price',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: '应用次数',
    key: 'useCount',
  },
];
const actionList = [
  {
    type: TableActionType.EDIT,
    name: '编辑',
  },
  {
    type: TableActionType.DELETE,
    name: '删除',
  },
  {
    type: TableActionType.VIEW,
    name: '历史记录',
  },
  // {
  //   type: TableActionType.USE,
  //   name: '应用至项目',
  // },
];
export default Vue.extend({
  name: 'detailTable',
  components: { EditQuota, HistoryList, CombinationTable, QuotaDetail },
  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
    /**
     * 操作列表
     */
    actionList: {
      type: Array,
      default: () => actionList,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要自定义字段
     */
    fieldSlots: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      detailedListBind: {
        actionList,
        pagination: this.pagination,
        tableData: [],
        columns,
        rowSelection: null,
        editFields: [],
        expandIconAsCell: true,
        originalIcon: true,
      },
      historyVisible: false,
      editVisible: false,
      id: '',
      currentData: null,
      actionRecord: {
        fxCode: '030404017',
        limit: 5,
        maxB: '',
        minB: '',
        orderField: 'price',
        orderType: 'asc',
        projectAttr:
          '1.名称:配电箱ALZ\n2.型号规格:1000*1000*185\n3.端子板外部接线材质、规格:NHBV-6\n4.安装方式:明装,底距地2.0m',
        unit: '台',
      }, // 当前点击清单的三要素
      deleteVisible: false,
      quotaFiledSlot: ['bdCode', 'bdName'],
      quotaDetailVisible: false,
      quotaDetailInfo: null,
    };
  },
  watch: {
    tableData: function (val) {
      if (val) this.detailedListBind.tableData = val;
    },
    pagination: {
      handler: function (val) {
        if (val) this.detailedListBind.pagination = val;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log('quotaTable.vue|84====>', this.$attrs);
  },
  methods: {
    viewRCJList(record) {
      this.quotaDetailInfo = record;
      this.quotaDetailVisible = true;
    },
    /**
     * 编辑失焦事件
     */
    editFieldHandler({ record, key, index }) {
      this.$emit('editHandler', { record, key, index });
    },
    actionHandler({ record, key, index }) {
      if (!checkUserIdentity(this)) return;
      this.$emit('actionHandler', { record, key, index });
      this.id = record.sequenceNbr;
      record.orgProjectAttr = record.projectAttr; // 作对比使用，是否修改过
      this.currentData = record;
      // this.actionRecord = {
      //   bdCode: record.bdCode,
      //   bdName: record.bdName,
      //   unit: record.unit,
      //   projectAttr: record.projectAttr,
      // };
      switch (key) {
        case 'view':
          this.historyVisible = true;
          break;
        case 'edit':
          this.editVisible = true;
          console.log('44444444', this.actionRecord);
          break;
        case 'delete':
          this.deleteVisible = true;
          break;
      }
    },
    refreshList() {
      this.$emit('refreshList');
    },
    paginationChange(pagination) {
      this.$emit('paginationChange', pagination);
    },
    deletePlanQuantity() {
      if (this.detailedListBind.tableData.length === 1) {
        this.$message.error('请移至成本组价项列表中进行删除');
        this.deleteVisible = false;
        return;
      }
      deleteBillDe((this.currentData as any).sequenceNbr).then((res) => {
        console.log(res);
        if (res.status === 200 && res.result) {
          this.$message.success('删除成功');
          this.refreshList();
        }
      });
      this.deleteVisible = false;
    },
  },
});
