<!--
 * @Descripttion: 历史记录弹框
 * @Author: liuxia
 * @Date: 2022-06-10 17:36:20
 * @LastEditors: renmingming
 * @LastEditTime: 2022-08-29 14:40:50
-->
<template>
  <div class="history-list">
    <customDrawer
      placement="right"
      width="80%"
      :visible="visible"
      @close="handleCancel"
      class="common-dialog drawer-history"
    >
      <template slot="title">
        <div class="drawer-title">
          <a-icon type="left" class="icon" @click="handleCancel" />
          历史记录
        </div>
      </template>
      <a-table
        rowKey="sequenceNbr"
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        bordered
        @change="paginationChange"
      >
        <template slot="options" slot-scope="text, record">
          <a-button type="link" @click="rollback(record)">替换</a-button>
        </template>
      </a-table>
    </customDrawer>
  </div>
</template>

<script>
import Vue from 'vue';
import { history, rollback } from '@/api/costQuotaLibrary/detail';
const columns = [
  {
    title: '序号',
    key: 'numberOrder',
    dataIndex: 'numberOrder',
    align: 'center',
    customCell: (record) => {
      return {
        style: { display: record.rowSpan === 0 ? 'none' : '' },
        attrs: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: '定额编码',
    key: 'bdCode',
    dataIndex: 'bdCode',
    align: 'center',
  },
  {
    title: '定额名称',
    key: 'bdName',
    dataIndex: 'bdName',
    align: 'center',
    width: 120,
  },
  {
    title: '单位',
    key: 'unit',
    dataIndex: 'unit',
    align: 'center',
  },
  {
    title: '综合单价(元)',
    key: 'billPrice',
    dataIndex: 'billPrice',
    align: 'center',
    customCell: (record) => {
      return {
        style: { display: record.rowSpan === 0 ? 'none' : '' },
        attrs: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: '人工费(元)',
    key: 'rfee',
    dataIndex: 'rfee',
    align: 'center',
  },
  {
    title: '材料费',
    key: 'cfee',
    dataIndex: 'cfee',
    align: 'center',
  },
  {
    title: '机械费(元)',
    key: 'jfee',
    dataIndex: 'jfee',
    align: 'center',
  },
  {
    title: '管理费及利润(元)',
    key: 'managerFee',
    dataIndex: 'managerFee',
    align: 'center',
  },
  {
    title: '定额综合单价(元)',
    key: 'price',
    dataIndex: 'price',
    align: 'center',
  },
  {
    title: '定额工程量',
    key: 'quantity',
    dataIndex: 'quantity',
    align: 'center',
  },
  {
    title: '定额合价(元)',
    key: 'total',
    dataIndex: 'total',
    align: 'center',
  },
  {
    title: '修改时间',
    key: 'billUpdateTime',
    dataIndex: 'billUpdateTime',
    align: 'center',
    customCell: (record) => {
      return {
        style: { display: record.rowSpan === 0 ? 'none' : '' },
        attrs: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: '项目应用次数',
    key: 'useCount',
    dataIndex: 'useCount',
    align: 'center',
    customCell: (record) => {
      return {
        style: { display: record.rowSpan === 0 ? 'none' : '' },
        attrs: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: '操作',
    key: 'options',
    align: 'center',
    scopedSlots: { customRender: 'options' },
    customCell: (record) => {
      return {
        style: { display: record.rowSpan === 0 ? 'none' : '' },
        attrs: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
];
export const groupBy = (list, fn) => {
  const groups = {};
  list.forEach(function (o) {
    const group = JSON.stringify(fn(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return groups;
};
export default Vue.extend({
  name: 'historyList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  watch: {
    visible: function (val) {
      if (val) this.historyList();
    },
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false);
    },
    // 获取列表数据
    historyList() {
      let apiData = {
        billSequenceNbr: this.id,
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      history(apiData).then((res) => {
        if (res.status === 200 && res.result) {
          this.tableData = [];
          this.pagination.total = res.result.total;
          let groupList = groupBy(res.result.list, (data) => {
            return data.numberOrder;
          });
          const startLength = res.result.list[0].numberOrder;
          const length =
            res.result.list[res.result.list.length - 1].numberOrder;
          for (let i = startLength; i <= length; i++) {
            groupList[i]?.forEach((item, index) => {
              if (index === 0) item.rowSpan = groupList[i].length;
              else item.rowSpan = 0;
              this.tableData.push(item);
            });
          }
        }
      });
    },
    // 替换功能
    rollback(record) {
      let apiData = this.tableData.filter(
        (x) => x.groupIdentifier === record.groupIdentifier
      );
      rollback(apiData).then((res) => {
        if (res.status === 200) {
          this.$message.success('替换成功');
          this.$emit('refreshList');
        }
      });
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
      };
      this.historyList();
    },
  },
});
</script>

<style lang="less">
.drawer-history {
  .ant-btn-link {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
}
</style>
