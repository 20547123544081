















































import Vue from 'vue';
import type { PropType } from 'vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import { MaterialType } from '@/interface/materialMachineLibrary';
import materialMachineTable from '@/views/enterpriseLibrary/materialMachineLibrary/component/materialMachineTable.vue';
import priceCompositionTable from '@/views/enterpriseLibrary/materialMachineLibrary/component/priceCompositionTable.vue';

const materialColumns = [
  {
    title: '名称',
    key: 'materialName',
  },
  {
    title: '规格型号',
    key: 'specification',
  },
  {
    title: '类别',
    key: 'kind',
  },
  {
    title: '计量单位',
    key: 'unit',
  },
  {
    title: '消耗量',
    key: 'resQty',
  },
  {
    title: '合计数量',
    key: 'sumNub',
  },
  {
    title: '定额价',
    key: 'dePrice',
  },
  {
    title: '市场价',
    key: 'marketPrice',
  },
  {
    title: '除税系数(%)',
    key: 'taxRemoval',
  },
  {
    title: '除税市场价',
    key: 'csMarketPrice',
  },
];
export default Vue.extend({
  name: 'quotaDetail',
  props: {
    adsQycbRcjModelList: {
      type: Array as PropType<MaterialType[]>,
      default: () => {
        return [];
      },
    },
    qyDePriceConstituteList: {
      type: Array as PropType<MaterialType[]>,
      default: () => {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentName: 'materialMachineTable',
      tableData: [] as MaterialType[],
      materialColumns: Object.freeze(materialColumns),
    };
  },
  watch: {
    adsQycbRcjModelList: {
      handler: function () {
        if (this.componentName === 'priceCompositionTable') return;
        this.tableData = this.adsQycbRcjModelList;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    tableChange(e) {
      // const value = e.target.value;
      if (this.componentName === 'materialMachineTable') {
        this.tableData = this.adsQycbRcjModelList;
      } else {
        this.tableData = this.qyDePriceConstituteList;
      }
    },
  },
  components: {
    materialMachineTable,
    priceCompositionTable,
  },
});
