var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-table"},[_c('combination-table',{attrs:{"detailedListBind":_vm.detailedListBind,"quotaBind":{
      actionList: [],
      editFields: [],
      fieldSlots: _vm.quotaFiledSlot,
    }},on:{"actionHandler":_vm.actionHandler,"paginationChange":_vm.paginationChange},scopedSlots:_vm._u([_vm._l((_vm.quotaFiledSlot),function(item,key){return {key:item,fn:function(ref){
    var record = ref.record;
    var text = ref.text;
return [_c('div',{key:key,staticClass:"link",on:{"click":function($event){return _vm.viewRCJList(record)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}})],null,true)}),_c('history-list',{attrs:{"visible":_vm.historyVisible,"id":_vm.id},on:{"update:visible":function($event){_vm.historyVisible=$event},"refreshList":_vm.refreshList}}),_c('edit-quota',{attrs:{"visible":_vm.editVisible,"currentData":_vm.currentData,"actionRecord":_vm.actionRecord},on:{"update:visible":function($event){_vm.editVisible=$event},"refreshList":_vm.refreshList}}),_c('a-modal',{staticClass:"common-dialog custom-common-dialog",attrs:{"width":"40%","visible":_vm.deleteVisible,"footer":false},on:{"cancel":function($event){_vm.deleteVisible = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"custom-title"},[_c('div',{staticClass:"title"},[_vm._v("删除提示")])])]},proxy:true}])},[_c('div',{staticClass:"tip-text"},[_c('span',[_vm._v("是否删除该成本组价方案？")])]),_c('div',{staticClass:"ant-modal-footer"},[_c('a-button',{on:{"click":function($event){_vm.deleteVisible = false}}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.deletePlanQuantity}},[_vm._v(" 确定 ")])],1)]),_c('quota-detail',{attrs:{"adsQycbRcjModelList":_vm.quotaDetailInfo && _vm.quotaDetailInfo.adsQycbRcjModelList,"qyDePriceConstituteList":_vm.quotaDetailInfo && _vm.quotaDetailInfo.qyDePriceConstituteList,"visible":_vm.quotaDetailVisible},on:{"update:visible":function($event){_vm.quotaDetailVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }